@font-face {
  font-family: "GabrielaStencilBlack";
  src: local("GabrielaStencilBlack"), url("./fonts/gabrielastencil-black.woff");
}

@font-face {
  font-family: "GabrielaStencilRegular";
  src: local("GabrielaStencilRegular"),
    url("./fonts/gabrielastencil-regular.woff");
}

@font-face {
  font-family: "GabrielaStencilBold";
  src: local("GabrielaStencilBold"), url("./fonts/gabrielastencil-bold.woff");
}

@font-face {
  font-family: "GabrielaStencilLight";
  src: local("GabrielaStencilLight"), url("./fonts/gabrielastencil-light.woff");
}

@font-face {
  font-family: "GabrielaStencilThin";
  src: local("GabrielaStencilThin"), url("./fonts/gabrielastencil-thin.woff");
}

@font-face {
  font-family: "GabrielaStencilUltraLight";
  src: local("GabrielaStencilUltraLight"),
    url("./fonts/gabrielastencil-ultralight.woff");
}

@font-face {
  font-family: "RobotoMonoBold";
  src: local("RobotoMonoBold"), url("./fonts/RobotoMono-Bold.ttf");
}

@font-face {
  font-family: "RobotoMonoRegular";
  src: local("RobotoMonoRegular"), url("./fonts/RobotoMono-Regular.ttf");
}