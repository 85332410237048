body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: #1a1a1a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spacer50  {
  height: 200px;
}

.divider {
  flex-grow: 1;
  border-bottom: 1px solid #fff;
  margin: 50px;
}

.dividerMainP {
  flex-grow: 0.15;
  border-bottom: 1px solid #fff;
  margin-left: 30px;
  margin-right: 30px;
}
.headingLines {
  position: relative;
  max-width: 100%;
  margin: 30px auto;
  text-align: center;
}

.headingLines:before {
  content: "";
  height: 1px;
  width: 40%;
  background: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.headingLines:after {
  content: " ";
  height: 1px;
  width: 40%;
  background: #fff;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  background: url() center center no-repeat;
  background-size: cover;
}

.smoke,
.smoke-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-image: url("https://www.miclients.com/users/sean/assets/mystery-oreo/smoke_PNG55245.png");
  /* z-index: 0; */
  background-size: cover;
}

.smoke-bg {
  background-image: url("https://www.miclients.com/users/sean/assets/mystery-oreo/smoke-bg.jpg");
  z-index: -1;
}

.smoke {
  -webkit-animation: float2 15s infinite ease-in-out;
          animation: float2 15s infinite ease-in-out;
}

.smoke-bg {
  -webkit-animation: float alternate 30s infinite ease-in-out;
          animation: float alternate 30s infinite ease-in-out;
}

@-webkit-keyframes float {
  0% {
    opacity: 0.8;
    transform: translateX(3%) scale(1.2);
  }
  50% {
    opacity: 0.8;
    transform: translateX(0) scale(1.4);
  }
  100% {
    opacity: 0.8;
    transform: translateX(-3%) scale(1.2);
  }
}

@keyframes float {
  0% {
    opacity: 0.8;
    transform: translateX(3%) scale(1.2);
  }
  50% {
    opacity: 0.8;
    transform: translateX(0) scale(1.4);
  }
  100% {
    opacity: 0.8;
    transform: translateX(-3%) scale(1.2);
  }
}
@-webkit-keyframes float2 {
  0% {
    opacity: 0;
    transform: translateX(-10%) scale(1.5);
  }
  50% {
    opacity: 1;
    transform: translateX(0) scale(1.2);
  }
  100% {
    opacity: 0;
    transform: translateX(10%) scale(1.5);
  }
}
@keyframes float2 {
  0% {
    opacity: 0;
    transform: translateX(-10%) scale(1.5);
  }
  50% {
    opacity: 1;
    transform: translateX(0) scale(1.2);
  }
  100% {
    opacity: 0;
    transform: translateX(10%) scale(1.5);
  }
}